import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Privacy = () => (
    <Layout>
         <SEO title="Privacy" description="Learn more about Emaildrop's privacy policy."/>
        <main>
            <div className="main-wrapper">
                <h1 className="title">Privacy</h1>
                <div className="privacy">
                    <div className="contents">
                        <div className="content">
                            <h2>Your personal data</h2>
                            <p className="paragraph">
                                Data protection is absolutely imperative to the service Emaildrop provides.
                                It is Emaildrop's policy to respect your privacy regarding any 
                                information we may collect from you across our websites, <a href="https://emaildrop.com"> https://emaildrop.com</a> and 
                                <a href=" https://api.emaildrop.com."> https://api.emaildrop.com.</a> The use of this 
                                site or any of the other site's operated by Emaildrop means you're consenting 
                                to have your personal data processed.
                            </p>
                        </div>
                        <div className="content">
                            <h2>Information we collect </h2>
                            <p className="paragraph">
                                In short, some information - such as IP address and/or browser and device
                                characteristics - is automatically collected when you visit our sites. This 
                                information does not reveal your specific identity (like your name and contact
                                information) but may include device and usage information such as your IP address,
                                browser and device characteristics, operating system, language preferences, 
                                referring URLs, device name, country, location, information about how and when 
                                you use our sites  and other technical information. This information is primarily needed
                                to maintain the security and operation of our sites, and for our internal analytics and 
                                reporting purposes. Like many businesses, we also collect information through cookies 
                                and similar technologies.
                            </p>
                        </div>
                        <div className="content">
                            <h2>How do we use your information? </h2>
                            <div>
                                We use the information we collect to or recieve:
                                <ul>
                                    <li>
                                        <b>To protect our sites:</b> We may use your
                                        information as part of our efforts to keep our sites safe
                                        and secure (for example, for fraud protection and prevention).
                                    </li>
                                    <li>
                                        <b>To enforce our terms, conditions and policies.</b>
                                    </li>
                                    <li>
                                        <b>To response to legal requests and prevent harm:</b> If we 
                                        receive a subpoena or other legal request, we may need to inspect the 
                                        data we hold to determine how to response.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                            <h2>Who do we share your information with? </h2>
                            <div>
                                In short, we only share information with your consent, to comply with laws,
                                to protect yours rights, or to fulfill business obligations such as sharing 
                                with third party services. Third party service providers used to monitor, deliver 
                                and anaylse the use of our service include:
                                <ul>
                                    <li>
                                        <b>AWS Cloudfront:</b> Amazon CloudFront is a fast content delivery network (CDN) 
                                        service that securely delivers data, videos, applications, and APIs to customers 
                                        globally with low latency, high transfer speeds, all within a developer-friendly environment.
                                    </li>
                                    <li>
                                        <b>Mailgun:</b> Emaildrop uses Mailgun to send, recieve and track emails. Although 
                                        when you delete a recieved email it is deleted immediately from our servers, Mailgun 
                                        keeps all emails for 3 days.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="content">
                            <h2>Do you we make updates to this policy? </h2>
                            <p>
                                In short, Yes. We will update this policy as necessary to stay 
                                compliant with relevant laws. If we make material changes to this privacy 
                                policy, we may notify you either by prominetly posting a notice of such changes
                                on our homepage or in our changelogs section.
                            </p>
                        </div>
                        <div className="content">
                            <h2>Who to contact us about this policy? </h2>
                            <p>
                                If you have any questions or comments about this policy, you can
                                email us at admin@api.emaildrop.io.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
)

export default Privacy;